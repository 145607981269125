
<template>
  <dl class="company-history">
    <dt class="company-history__dt">昭和48年8月</dt>
    <dd class="company-history__dd">大分市豊海5丁目3番8号に前身である大分自動車整備株式会社第3工場として認証を受ける [ 認証番号5-712 ]</dd>
    <dt class="company-history__dt">昭和51年8月</dt>
    <dd class="company-history__dd">資本金300万円にて神栄車輌有限会社を設立、代表取締役に 内野 優 が就任</dd>
    <dt class="company-history__dt">昭和52年5月</dt>
    <dd class="company-history__dd">九州日野自動車㈱の協力指定サービス工場となる</dd>
    <dt class="company-history__dt">昭和62年2月</dt>
    <dd class="company-history__dd">社名をシンエイ車輌有限会社に変更</dd>
    <dt class="company-history__dt">平成6年10月</dt>
    <dd class="company-history__dd">極東開発工業株式会社の指定サービス工場となる</dd>
    <dt class="company-history__dt">平成21年6月</dt>
    <dd class="company-history__dd">大分市大字大在2番地へ移転</dd>
    <dt class="company-history__dt">平成22年4月</dt>
    <dd class="company-history__dd">九州陸運局指定工場となる [ 指定工場 大-588 ]</dd>
    <dt class="company-history__dt">平成22年8月</dt>
    <dd class="company-history__dd">オナン発電機の協力サービス工場となる</dd>
    <dt class="company-history__dt">平成22年12月</dt>
    <dd class="company-history__dd">古物商許可を受ける</dd>
    <dt class="company-history__dt">平成25年3月</dt>
    <dd class="company-history__dd">代表取締役に 鍵野 宝寿 が就任</dd>
    <dt class="company-history__dt">平成28年7月</dt>
    <dd class="company-history__dd">高所作業車の特定自主検査業者の登録</dd>
    <dt class="company-history__dt">平成29年12月</dt>
    <dd class="company-history__dd">日本トレクス株式会社の協力サービス工場となる<br>現在に至る</dd>
  </dl>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.company-history {
  width: calc(100vw - 152px * 2);
  min-width: 60%;
  max-width: 78%;
  font-size: 2rem;
  line-height: 3rem;
  &__dt {
    display: inline-block;
    width: 240px;
    font-weight: 600;
    vertical-align: top;
  }
  &__dd {
    display: inline-block;
    width: calc(100% - 240px);
    margin-bottom: 45px;
    vertical-align: top;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
