<template>
  <p class="company-philosophy">
    常に向題意識を持ち、剣意工夫して考動し<br>
    最高の技術力と誠意をもって<br>
    最大の顧客満足度を提供する事を誓い<br>
    以って社会に貢献する<br>
  </p>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.company-philosophy {
  margin: 18px 0 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
}
</style>
