<template>
  <table class="company-table">
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">商号</td>
      <td class="company-table__tr__td--right">シンエイ車輌有限会社</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">よみがな</td>
      <td class="company-table__tr__td--right">しんえいしゃりょう</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">所在地</td>
      <td class="company-table__tr__td--right">本社        大分県大分市大字大在2番地</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">電話番号</td>
      <td class="company-table__tr__td--right">097-547-7723</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">創立</td>
      <td class="company-table__tr__td--right">昭和51年8月28日</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">資本金</td>
      <td class="company-table__tr__td--right">3,000,000円</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">最寄り駅</td>
      <td class="company-table__tr__td--right">大在駅</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">最寄り駅からの距離</td>
      <td class="company-table__tr__td--right">大在駅から直線距離で1419m</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">従業員</td>
      <td class="company-table__tr__td--right">
        <table class="company-table__tr__td__table">
          <tr class="company-table__tr__td__table__tr">
            <td class="company-table__tr__td__table__tr__td--left">12名</td>
            <td class="company-table__tr__td__table__tr__td--center">検査員</td>
            <td class="company-table__tr__td__table__tr__td--right">4名</td>
          </tr>
          <tr class="company-table__tr__td__table__tr">
            <td class="company-table__tr__td__table__tr__td--left" />
            <td class="company-table__tr__td__table__tr__td--center">整備主任資格者</td>
            <td class="company-table__tr__td__table__tr__td--right">5名</td>
          </tr>
          <tr class="company-table__tr__td__table__tr">
            <td class="company-table__tr__td__table__tr__td--left" />
            <td class="company-table__tr__td__table__tr__td--center">2級整備士資格者</td>
            <td class="company-table__tr__td__table__tr__td--right">5名</td>
          </tr>
          <tr class="company-table__tr__td__table__tr">
            <td class="company-table__tr__td__table__tr__td--left" />
            <td class="company-table__tr__td__table__tr__td--center">3級整備士資格者</td>
            <td class="company-table__tr__td__table__tr__td--right">3名</td>
          </tr>
          <tr class="company-table__tr__td__table__tr">
            <td class="company-table__tr__td__table__tr__td--left" />
            <td class="company-table__tr__td__table__tr__td--center">高所作業車検査員</td>
            <td class="company-table__tr__td__table__tr__td--right">2名</td>
          </tr>
          <tr class="company-table__tr__td__table__tr">
            <td class="company-table__tr__td__table__tr__td--left" />
            <td class="company-table__tr__td__table__tr__td--center">移動式クレーン検査者</td>
            <td class="company-table__tr__td__table__tr__td--right">2名</td>
          </tr>
        </table>
      </td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">取引先銀行</td>
      <td class="company-table__tr__td--right">
        大分銀行中島支店<br>
        大分県信用組合本店<br>
        大分みらい信用金庫大分支店</td>
    </tr>
    <tr class="company-table__tr">
      <td class="company-table__tr__td--left">主要取引先</td>
      <td class="company-table__tr__td--right">極東開発工業㈱・九州日野自動車㈱大分市役所・鶴海運輸㈱・キデンリース㈱・川崎近海汽船㈱・ ㈱シーゲートコーポレーション・日本通運㈱・㈱ヨコハマタイヤットワークサービスカ州・東京海上日動火災保険㈱・九産ポンプ(有)</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.company-table {
  width: calc(100vw - 152px * 2);
  min-width: 60%;
  max-width: 78%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid $blue_color;
  &__tr {
    font-size: 1.8rem;
    vertical-align: top;
    &__td {
      padding: 26px 40px;
      border: 1px solid $blue_color;
      &--left {
        @extend .company-table__tr__td;
        width: 378px;
      }
      &--right {
        @extend .company-table__tr__td;
      }
      &__table {
        width: 100%;
        &__tr {
          width: 100%;
          &__td {
            &--left {
              width: 100px;
            }
            &--right {
              width: 75px;
            }
          }
        }
      }
    }
  }
}
</style>
