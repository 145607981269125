<template>
  <div :class="isSP ? 'company--sp' : 'company--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="company__visual--sp" :img="require('@/assets/img/visual/company_sp.jpeg')" title="会社概要" />
    <pc-visual v-else class="company__visual--pc" :img="require('@/assets/img/visual/company_pc.jpeg')" title="会社概要" />
    <!-- メッセージ -->
    <sp-message v-if="isSP" class="company__message--sp" />
    <pc-message v-else class="company__message--pc" />
    <!-- 理念 -->
    <h2 :class="isSP ? 'company__title--sp' : 'company__title--pc'">理念</h2>
    <sp-philosophy v-if="isSP" class="company__philosophy--sp" />
    <pc-philosophy v-else class="company__philosophy--pc" />
    <!-- 沿革 -->
    <h2 :class="isSP ? 'company__title--sp' : 'company__title--pc'">会社沿革</h2>
    <sp-history v-if="isSP" class="company__history--sp" />
    <pc-history v-else class="company__history--pc" />
    <!-- 表 -->
    <h2 :class="isSP ? 'company__title--sp' : 'company__title--pc'">会社概要</h2>
    <sp-table v-if="isSP" class="company__table--sp" />
    <pc-table v-else class="company__table--pc" />
    <!-- 問い合わせ -->
    <sp-contact v-if="isSP" :isRecruit="false" />
    <pc-contact v-else :isRecruit="false" />
    <!-- ボトムイメージ -->
    <div v-if="isSP" class="company__images--sp">
      <v-img class="company__images__item--sp" :src="require('@/assets/img/company/image1.jpeg')" />
      <v-img class="company__images__item--sp" :src="require('@/assets/img/company/image2.jpeg')" />
    </div>
    <div v-else class="company__images--pc">
      <v-img class="company__images__item--pc" :src="require('@/assets/img/company/image1.jpeg')" />
      <v-img class="company__images__item--pc" :src="require('@/assets/img/company/image2.jpeg')" />
    </div>
  </div>
</template>

<script>
import SpVisual from '@/components/common/visual/sp'
import PcVisual from '@/components/common/visual/pc'
import SpMessage from '@/components/company/message/sp'
import PcMessage from '@/components/company/message/pc'
import SpPhilosophy from '@/components/company/philosophy/sp'
import PcPhilosophy from '@/components/company/philosophy/pc'
import SpHistory from '@/components/company/history/sp'
import PcHistory from '@/components/company/history/pc'
import SpTable from '@/components/company/table/sp'
import PcTable from '@/components/company/table/pc'
import SpContact from '@/components/common/contact/sp'
import PcContact from '@/components/common/contact/pc'

export default {
  name: 'company',
  components: { SpVisual, PcVisual, SpMessage, PcMessage, SpPhilosophy, PcPhilosophy, SpHistory, PcHistory, SpTable, PcTable, SpContact, PcContact },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.company {
  width: 100%;
  &--sp {
    @extend .company;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .company;
    margin-top: $pc_header_height;
  }
  &__title {
    text-align: center;
    &--pc {
      @extend .company__title;
      margin: 130px 0 0;
      font-size: 2.5rem;
      font-weight: 800;
    }
    &--sp {
      @extend .company__title;
      margin: 40px 0 0;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  &__message {
    &--pc {
      @extend .company__message;
      margin-top: 100px;
    }
  }
  &__history {
    &--sp {
      @extend .company__history;
      margin: 24px auto 0 auto;
    }
    &--pc {
      @extend .company__history;
      margin: 68px auto 0 auto;
    }
  }
  &__table {
    &--sp {
      @extend .company__table;
      margin: 24px auto 0 auto;
    }
    &--pc {
      @extend .company__table;
      margin: 68px auto 0 auto;
    }
  }
  &__images {
    &--sp {
      @extend .company__images;
      width: 100vw;
      margin-top: 100px;
    }
    &--pc {
      @extend .company__images;
      width: 100vw;
      margin-top: 230px;
    }
    &__item {
      width: 100%;
      &--sp {
        @extend .company__images__item;
        height: 237px;
      }
      &--pc {
        @extend .company__images__item;
        height: 693px;
      }
    }
  }
}
</style>
