<template>
  <h1 class="company-message">“はたらく車の整備を通じて<br>社会に安心と安全をお届けします”</h1>
</template>

<script>
export default {
  props: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.company-message {
  margin-top: 57px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: center;
}
</style>
